import { graphql } from "gatsby"
import React from "react"
import CookiesBanner from "../components/CookiesBanner/CookiesBanner"
import styles from "../styles/index.module.scss"
import DesktopContent from "../components/pages/Home/DesktopHome"
import MobileContent from "../components/pages/Home/MobileHome"

const lang = "en"

const IndexPage = ({ data, pageContext }) => {
  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "HOME"
  })

  const {
    childImageSharp: { fluid: homeBackground },
  } = data.allFile.nodes.find(
    node => node.childImageSharp.fluid.src.indexOf(`home-mobile`) > -1
  )

  //get seo data
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_en,
    titleSeo: seoDataJson.title_en,
    description: seoDataJson.description_en,
    alternateLanguage: seoDataJson.alternateLanguage_en,
    alternateUrl: seoDataJson.alternateUrl_en,
  }
  const thumbnail = data.thumbnail.childImageSharp.fluid.base64

  // get logos url
  const rodiLogoUrl = data.logos.nodes.find(
    unilogo => unilogo.publicURL.indexOf("logo-rodi-white") > -1
  ).publicURL
  const nouCimsLogoUrlWhite = data.logos.nodes.find(
    unilogo => unilogo.publicURL.indexOf("logo-nous-cims-white") > -1
  ).publicURL
  const portAventuraLogoWhite = data.logos.nodes.find(
    unilogo => unilogo.publicURL.indexOf("logo-portAventura-white") > -1
  ).publicURL
  const portAventuraLogo = data.logos.nodes.find(
    unilogo => unilogo.publicURL.indexOf("logo-portAventura-blue") > -1
  ).publicURL
  const oxfamLogo = data.logos.nodes.find(
    unilogo => unilogo.publicURL.indexOf("logo-intermon-blue") > -1
  ).publicURL

  return (
    <div className={styles.wrapper}>
      <div className={styles.mobile}>
        <MobileContent
          fotos={data.allFile.nodes}
          resultUrls={resultUrls}
          seoData={seoData}
          homeBackground={homeBackground}
          rodiLogoUrl={rodiLogoUrl}
          nouCimsLogoUrlWhite={nouCimsLogoUrlWhite}
          portAventuraLogo={portAventuraLogoWhite}
          oxfamLogo={oxfamLogo}
          lang={lang}
        />
      </div>
      <div className={styles.desktop}>
        <DesktopContent
          thumbnail={thumbnail}
          fotos={data.allFile.nodes}
          resultUrls={resultUrls}
          seoData={seoData}
          rodiLogoUrl={rodiLogoUrl}
          nouCimsLogoUrlWhite={nouCimsLogoUrlWhite}
          portAventuraLogo={portAventuraLogo}
          oxfamLogo={oxfamLogo}
          lang={lang}
        />
        <CookiesBanner location={"home"} language={"en"} />
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query homeEnQuery {
    allFile(filter: { ext: { regex: "/jpg|png/" } }) {
      nodes {
        extension
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    logos: allFile(filter: { extension: { eq: "svg" } }) {
      nodes {
        name
        publicURL
      }
    }
    seoDataJson(name: { eq: "home" }) {
      url_en
      title_en
      description_en
      alternateLanguage_en
      alternateUrl_en
    }
    thumbnail: file(relativePath: { eq: "video_first_frame.png" }) {
      childImageSharp {
        fluid(quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
